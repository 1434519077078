import { Box, Container } from '@mui/material'
import React from 'react'
import { Header } from '../header-footer/Header'
import { Footer } from '../header-footer/Footer'
import CourseSection from '../section/CourseSection'

export const Courses = () => {
  return (
    <>
    <Header/>
    {/* -----------------content---------------- */}
        <Box className='about-head'>
          <Container>
          <h1>Courses</h1>
          </Container>
        </Box>
    
    {/* ------------------sections----------------- */}

        <CourseSection/>



    {/* --------footer-------------------------- */}
      <Footer/>
    </>
  )
}
