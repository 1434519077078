import React from 'react'
import { Header } from '../header-footer/Header'
import { Footer } from '../header-footer/Footer'
import { Box, Container } from '@mui/material'
import computer from '../images/computer.png'
import { Link } from 'react-router-dom'

const BasicComputer = () => {
  return (
    <>
      <Header />
      <Container>
        <Box className='account-container' >
          <Box>
            <h2 className='abt'>Basics of Computer</h2>
            <p className='abt-content'>Learn the fundamentals of computer usage with our complete Basic Computer Course. This course is ideal for beginners and covers essential skills such as operating systems, file management, internet browsing, and basic software programs, providing you with the fundamental knowledge needed for everyday computing tasks.</p>
            <h3 style={{marginTop:'10px'}}>Course Duration : 3 to 5 months</h3>
          </Box>
        </Box>
        <Box className='account-container' sx={{flexDirection:{xs:'column-reverse',sm:'row'}}}>
          <Box className='account-part' >
            <h2 className='abt'>Course Covers</h2>
            <Box sx={{ paddingLeft: '20px', width: '100%' }}>
              <ul className='account-ulist'>
                <li>
                  <h3>Getting Started with Computers</h3>

                  <ul>
                    <li>Study of H/W Components & Pheripherals</li>
                    <li>General Operation (Cd Writting, Scanning, Printing)</li>
                    <li>Memory Management</li>
                    <li>Operating System</li>
                  </ul>

                </li>
                <li>
                  <h3>Ms-Windows</h3>

                  <ul>
                    <li>Configuration & Customizing Windows</li>
                    <li>Handling Files & Folders</li>
                    <li>Using Accessories</li>
                  </ul>

                </li>
                <li>
                  <h3>Ms-Office</h3>

                  <ul>
                    <li>Word Processing using Ms-Word</li>
                    <li>Electronic Spreadsheet using Ms-Excel</li>
                    <li>Multimedia Presentation using Ms-PowerPoint</li>
                  </ul>

                </li>
                <li>
                  <h3>Drilling into Internet</h3>

                  <ul>
                    <li>Working with E-Mailing, Search Engines, Chat Channels</li>
                    <li>Downloading, INternet Banking, Railway Reservation, Online Shopping,etc.</li>
                  </ul>

                </li>

                <li><h3>Project</h3></li>
                <li><h3>Examination & Certificate</h3></li>
              </ul>
            </Box>
          </Box>
          <Box className='account-part' sx={{ marginTop: '20px', height: '300px' }}>
            <img src={computer} className='account-img' alt='account-img' />
          </Box>
        </Box>
        <Box sx={{marginBottom:'20px'}}><Link to={'/contact'} style={{color:'black'}}>For More Details Contact Us</Link></Box>
      </Container>

      <Footer />
    </>
  )
}

export default BasicComputer