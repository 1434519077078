import React from 'react'
import { Footer } from '../header-footer/Footer'
import { Header } from '../header-footer/Header'
import { Box, Container } from '@mui/material'
import dsa from '../images/datastructure.png'
import { Link } from 'react-router-dom'

const DSA = () => {
  return (
    <>
        <Header/>
        {/* -----------content----------------- */}

      <Container>
        <Box className='account-container'>
          <Box className='account-part'>
            <h2 className='abt'>Data Structure & Algorithims</h2>
            <p className='abt-content'>Learn the principles of data structures with our complete course. Explore key principles and algorithms for efficiently managing and manipulating data.</p>
            <h3 style={{marginTop:'10px'}}>Course Duration : 3 to 4 months</h3>
            <h3 className='abt'>Course Content</h3>
            <ul style={{ listStyleType: 'circle', listStylePosition: 'inside', fontSize: '14px' }}>
              <li>Introduction to Data Structures: Importance and basic concepts</li>
              <li> Arrays and Linked Lists: Implementation and operations</li>
              <li> Stacks and Queues: Fundamental data structures and their applications</li>
              <li>Trees: Binary Trees, Binary Search Trees, AVL Trees, and their operations</li>
              <li> Graphs: Representation and traversal algorithms (DFS, BFS)</li>
              <li>Sorting and Searching Algorithms: Bubble Sort, Quick Sort, Merge Sort, Linear Search, Binary Search</li>
              <li>Hashing: Hash tables and collision resolution techniques</li>
              <li>Dynamic Programming: Principles and applications in problem-solving</li>
              <li>Advanced topics: Red-Black Trees, B-trees, Heaps</li>
              <li>Practical implementation of algorithms and data structures through coding exercises and projects.</li>
            </ul>
          </Box>
          <Box className='account-part'>
            <img src={dsa} className='account-img' alt='account-img' />
          </Box>
        </Box>
        <Box sx={{marginBottom:'20px'}}><Link to={'/contact'} style={{color:'black'}}>For More Details Contact Us</Link></Box>

      </Container>

      {/* ----------footer-------------------- */}
       <Footer/> 
    </>
  )
}

export default DSA