import React from 'react'
import { Footer } from '../header-footer/Footer'
import { Header } from '../header-footer/Header'
import { Box, Container } from '@mui/material'
import python from '../images/python.png'
import { Link } from 'react-router-dom'

const Python = () => {
  return (
    <>
        <Header/>
        {/* -----------content----------------- */}

      <Container>
        <Box className='account-container'>
          <Box className='account-part'>
            <h2 className='abt'>Python Programming</h2>
            <p className='abt-content'>Join our complete course to learn Python programming. From basic syntax to advanced topics, discover the versatility and impact of this popular language.</p>
            <h3 style={{marginTop:'10px'}}>Course Duration : 3 to 4 months</h3>
            <h3 className='abt'>Course Content</h3>
            <ul style={{ listStyleType: 'circle', listStylePosition: 'inside', fontSize: '14px' }}>
              <li>Introduction to Python: its history, features, and applications</li>
              <li>  Basic syntax, data types, and control structures</li>
              <li> Functions, modules, and packages for code organization</li>
              <li>Object-oriented programming principles: classes, objects, inheritance, polymorphism</li>
              <li> File handling and input/output operations</li>
              <li>Practical exercises and projects to reinforce learning</li>
            </ul>
          </Box>
          <Box className='account-part'>
            <img src={python} className='account-img' alt='account-img' />
          </Box>
        </Box>
        <Box sx={{marginBottom:'20px'}}><Link to={'/contact'} style={{color:'black'}}>For More Details Contact Us</Link></Box>

      </Container>

      {/* ----------footer-------------------- */}
       <Footer/> 
    </>
  )
}

export default Python