import { Box, Container } from '@mui/material'
import React from 'react'
import course from '../images/courses.png'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import data2 from '../data/DataCourse'
import { Link } from 'react-router-dom';

const CourseSection = () => {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 2000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 2000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 500 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 500, min: 0 },
            items: 1
        }
    };

    return (
        <>
            <Container>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: { xs: 'column', sm: 'row' } }}>
                    <Box sx={{ width: { xs: '100%', sm: '60%' }, marginRight: '20px' }}>
                        <h2 className='abt'>Courses</h2>
                        <p className='abt-content'>Welcome to our Courses Page, which provides access to a wide range of skill-building possibilities. Explore the wide range of courses designed to empower learners in a variety of subjects and sectors. From mastering the art of web development and design to honing essential skills in front-end development, Tally, graphics design, computer basics, spoken English, and diploma programs, we provide a comprehensive learning experience that meets your career goals and personal development objectives. Discover the ideal course to help you reach your full potential and go on a lifelong learning journey with us.</p>
                    </Box>
                    <Box sx={{ width: '40%', textAlign: 'center', paddingTop: '40px' }} >
                        <img src={course} alt='about' style={{ width: '200px' }} />
                    </Box>
                </Box>
            </Container>
            <Container>
                <section id='popularcourse'>
                    <div className='container'>
                        <h3 className='heading'>Our Courses</h3>
                        <div style={{
                            margin: "auto",
                            maxWidth: "1200px",
                            width: "100%",
                            padding: "20px"
                        }} >
                            <Carousel responsive={responsive} autoPlay={true} autoPlaySpeed={3000} arrows={false} showDots rewind={true} rewindWithAnimation={true} shouldResetAutoplay={true} >
                                {
                                    data2.map((item, index) => {
                                        return (
                                            <div key={index} className='card' style={{ marginBottom: '30px' }}>
                                                <img src={item.img} alt='cardimage' />
                                                <h3>{item.name}</h3>
                                                <p>{item.des}</p>
                                                <Link to={item.src} className='btn'>read more</Link>
                                            </div>
                                        )
                                    })
                                }
                            </Carousel>
                        </div>
                    </div>
                </section>
            </Container>

        </>
    )



}


export default CourseSection;



