import React from 'react'
import { Header } from '../header-footer/Header'
import { Footer } from '../header-footer/Footer'
import { Box, Container } from '@mui/material'
import Card from '../section/Card'
import data from '../data/Data3'
import { Link } from 'react-router-dom'

const Programming = () => {
  const card = data.map(item => {
    return <Card image={item.img} name={item.name} des={item.des} src={item.src}/>
})
  return (
    <>
    <Header/>
    {/* --------------content-------------------- */}

    <Container>
      <Box className='account-container'>
        <Box>
        <h2 className='abt'>Programming</h2>
        <p className='abt-content'>Welcome to our Programming Page, where you can embark on a journey to master various programming languages and Data Structures & Algorithms (DSA). Whether you're a beginner or an experienced coder, our comprehensive courses in C++, C, Python, Java, and DSA offer you the opportunity to enhance your coding skills and excel in the world of software development.</p>
        </Box>
      </Box>
      <section id='popularcourse'>
                <div className='container'>
                    <div className='wrapper'>
                        {card}
                    </div>
                </div>
            </section>
        <Box sx={{marginBottom:'20px'}}><Link to={'/contact'} style={{color:'black'}}>For More Details Contact Us</Link></Box>

    </Container>

    {/* --------------footer------------------- */}
    <Footer/>
    </>
  )
}

export default Programming