import CProgram from '../images/C.png'
import CPlus from '../images/C++.png'
import Python from '../images/python.png'
import Java from '../images/java.png'
import DSA from '../images/datastructure.png'

const data = [
    {
        img: CProgram,
        name: 'C',
        des: 'Master the basics of the C programming language, including syntax, data types, control structures, and memory management.',
        src: '/Cprogramming'
    },
    {
        img: CPlus,
        name: 'C++',
        des : 'Learn the fundamentals of object-oriented programming and advanced features of C++ to develop efficient and scalable applications.',
        src:'/Cplus'
    },
    {
        img: Python,
        name: 'Python',
        des : 'Learn about Python, a flexible and beginner-friendly programming language, and discover its applications in web development, data analysis, and automation.',
        src:'/python'
    },
    {
        img: Java,
        name: 'Java',
        des : 'Gain expertise in Java programming, from basic syntax to advanced topics such as multithreading, networking, and GUI development.',
        src:'/java'
    },
    {
        img: DSA,
        name: 'DSA',
        des : 'Understand the core concepts of DSA, including arrays, linked lists, stacks, queues, trees, sorting algorithms, and searching techniques.',
        src:'/dsa'
    }
];

export default data;