import React from 'react'
import '../styles/PlacementForm.css'

const PlacementForm = () => {
  return (
    <>
        <div className='form'>
            <div className='form-head'>Send Details</div>
            <div className="form-body">
                    <div className="form-box">
                        <input type="text" placeholder="Enter your name" required/>
                    </div>
                    <div className="form-box">
                        <input type="text" placeholder="Enter your email" required/>
                    </div>
                    <div className="form-box">
                        <input type="text" placeholder="Enter your mobile number" required/>
                    </div>
                    <div className="form-box">
                        <input type="text" placeholder="Enter your city" required/>
                    </div>
                    <div className="form-box">
                        <input type="text" placeholder="Enter your college name" required/>
                    </div>
                    <div className="form-box">
                        <input type="text" placeholder="Enter your branch" required/>
                    </div>
                    <div className="form-box message">
                        <textarea placeholder="Your message"></textarea>
                    </div>
                    <div className="button">
                        <input type="button" value="Send Now"/>
                    </div>
                </div>
        </div>
    </>
  )
}

export default PlacementForm