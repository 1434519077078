import { Box, Container } from '@mui/material'
import React from 'react'
import '../styles/Aboutstyle.css'
import abtimage from '../images/about.jpg'
import { Footer } from '../header-footer/Footer'
import { Header } from '../header-footer/Header'

export const About = () => {
  return (
    <>
    <Header/>
      <Box >
        <Box className='about-head'>
          <Container>
          <h1>About Us</h1>
          </Container>
        </Box>
        <Container sx={{ padding: '20px',marginBottom:'30px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Box sx={{ width:{xs:'100%',sm:'60%'}, marginRight: '30px' }}>
              <h2 className='abt' style={{marginTop:'0'}}>About</h2>
              <p className='abt-content'>Genext Institute, founded in 2003, offers a wide range of computer courses including website designing, development, graphic design, PHP, Java, SEO, digital marketing, AutoCAD, 3D Max, Android development, and more. Our goal is to fulfill societal aspirations by providing skilled technical and non-technical manpower. Over 14 years, we've established ourselves as a leading institute in Madhya Pradesh, placing over 5000 students in their respective fields. With a focus on dedication, discipline, and quality, we provide world-class education and lifelong benefits. Our institute continuously evolves with advanced infrastructure and human resources to meet current and future technological demands. Congratulations to our faculty, staff, and students for contributing to our success. Genext is your destination for premium computer courses with 100% job guarantee, helping you achieve your dream career.</p>
            </Box>
            <Box sx={{ width: '40%' ,display:{xs:'none', sm:'block'}}} >
              <img src={abtimage} alt='about' className='abt-img' style={{borderRadius:'4px'}} />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' ,flexDirection:{xs:'column',sm:'row'}}}>
            <Box sx={{width:{xs:'100%',sm:'50%'},marginRight:{xs:'10px',sm:'30px'}}}>
              <h2 className='abt'>Our Mission</h2>
              <p className='abt-content'>The main focus of this program is to provide deep adequate knowledge of computers to the student and also  pull out the hidden abilities and inner power of the student. It aims to enhance lifestyles through awareness of new technologies and offers computer courses at affordable fees.</p>
            </Box>
            <Box sx={{width:{xs:'100%',sm:'50%'}}}>
              <h2 className='abt'>Our Vision</h2>
              <p className='abt-content'>
                Genext aims to be acknowledged as a specialist in flexible vocational education. It will be renowned for its inventive teaching approaches and personalized services, enhancing student experiences across various educational aspects such as courses, programs, and activities.</p>
            </Box>
          </Box>
          <Box>
          <h2 className='abt'>Special Feature</h2>
          <p className='abt-content'>
          Through the Rashtriya Computer Academy, Genext offers free computer education to individuals from marginalized backgrounds such as SC/ST/OBC, widows, and the handicapped. This initiative enables them to acquire computer skills without any course-related fees.</p>
          </Box>
          <Box>
          <h2 className='abt'>Why Choose Us?</h2>
          <p className='abt-content'>
          If you're seeking the top institute in Madhya Pradesh for all computer courses, your search ends here at Genext! With years of experience, we've assisted numerous candidates in gaining both knowledge and securing their dream jobs. Our premium courses guarantee 100% job assistance, making Genext the ultimate destination for your career aspirations.</p>
          </Box>
        </Container>
      </Box>
      <Footer/>
    </>
  )
}
