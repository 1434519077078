import React from 'react'
import '../styles/PlacementForm.css'
import '../styles/ContactSection.css'
import { Box, Container } from '@mui/material'
import { FaFacebookSquare } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { Link } from 'react-router-dom';

const ContactForm = () => {
    return (
        <>
            <Container sx={{marginTop:'0'}}>
                <section id='contact'>
                    <div className='contact-container'>
                        <div className='contact-content'>
                            <div className='left'>
                                <h1 style={{marginBottom:'20px'}}>Connect with Us</h1>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection:{xs:'row',sm:'column'} }}>
                                    <div className='contact-social'>
                                        <Link to={'https://www.facebook.com/genextpalasia/'}>
                                            <FaFacebookSquare  />
                                        </Link>
                                    </div>
                                    <div className='contact-social'>
                                        <Link to={'https://www.youtube.com/channel/UC8WD0V2XVqa7xfbpQgVTirg?view_as=subscriber'}>
                                            <FaYoutube  />
                                        </Link>
                                    </div>
                                    <div className='contact-social'>
                                        <Link to={'https://twitter.com/genextweb16'}>
                                            <FaTwitter  />
                                        </Link>
                                    </div>
                                    <div className='contact-social'>
                                        <Link to={'https://www.linkedin.com/in/genextweb/'}>
                                            <FaLinkedin />
                                        </Link>
                                    </div>
                                </Box>

                            </div>
                            <div className='right'>

                                <div className="form-body">
                                    <div className="form-box">
                                        <input type="text" placeholder="Enter your name" required />
                                    </div>
                                    <div className="form-box">
                                        <input type="text" placeholder="Enter your email" required />
                                    </div>
                                    <div className="form-box">
                                        <input type="text" placeholder="Enter your mobile number" required />
                                    </div>
                                    <div className="form-box message">
                                        <textarea placeholder="Your message"></textarea>
                                    </div>
                                    <div className="button">
                                        <input type="button" value="Send Now" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Container>
        </>
    )
}

export default ContactForm