import React from 'react'
import { Header } from '../header-footer/Header'
import { Footer } from '../header-footer/Footer'
import { Box, Container } from '@mui/material'
import { Link } from 'react-router-dom'

const Diploma = () => {
  return (
    <>
      <Header/>
      

      {/* ------------content--------------------- */}

        <Container>
        <Box className='account-container'>
          <Box>
            <h2 className='abt'>Diploma</h2>
            <p className='abt-content'>Our Diploma Programs will take you on a journey of endless possibilities. Our diploma courses are designed for ambitious individuals searching specialized knowledge and skill development. They provide the path to career success and personal growth. Our diplomas prepare you to succeed in today's competitive labor market by offering flexible eligibility requirements and a variety of benefits such as hands-on training, industry-relevant content, and certification upon completion.</p>
          </Box>
        </Box>

        <Box className='account-container' sx={{justifyContent:'flex-start',alignItems:'flex-start'}}>
          <Box className='account-part'>
            <h2 className='abt'>DCA (Diploma in Computer Application)</h2>
            <p className='abt-content'>DCA, or Diploma in Computer Applications, is a comprehensive program that provides learners with fundamental computer skills and expertise. This course is suitable for both beginners and professionals. It covers key concepts like as operating systems, word processing, spreadsheets, database administration, internet usage, and programming foundations.
            </p>
            <h3 style={{margin:'10px 0'}}>Eligibility : 12<sup>th</sup> in any Discipline</h3>
            <h3 style={{margin:'10px 0'}} >Duration : 1 Year</h3>
            <h3 style={{margin:'10px 0'}}>Program Covers :</h3>
            <ul style={{ listStyleType: 'circle', listStylePosition: 'inside' ,fontSize:'14px'}}>
              <li>Fundamentals of Computers and Information Tech</li>
              <li>Operating System</li>
              <li>Pc Packages (Windows-Ms Word and Ms-Excel)</li>
              <li>Application Programming</li>
               <li> Financial Accounting-Tally</li>
                <li>Opps Programming C++</li>
                <li>Gui Programming</li>
                <li>Web Technology & Internet</li>
                <li>Introduction to Entrepreneurship</li>
                <li>Project</li>
            </ul>

            
          </Box>
          <Box className='account-part' >
          <h2 className='abt'>PGDCA (Post Graduate Diploma in Computer Application)</h2>
            <p className='abt-content'>PGDCA, or Post Graduate Diploma in Computer Applications, is a highly sophisticated degree designed to satisfy the growing demand for qualified IT professionals. This course is ideal for graduates and working professionals who want to specialize in computer applications. It covers advanced topics such software development, database management, web technologies, and IT project management.
            </p>
            <h3 style={{margin:'10px 0'}}>Eligibility : Graduate in any Discipline</h3>
            <h3 style={{margin:'10px 0'}}>Duration : 1 Year</h3>
            <h3 style={{margin:'10px 0'}}>Program Covers :</h3>
            <h3>Part - I</h3>
            <ul style={{ listStyleType: 'circle', listStylePosition: 'inside' ,fontSize:'14px'}}>
              <li>Fundamentals of Computers and Information Tech</li>
              <li>Operating System (Dos, Linux, Windows)</li>
              <li>Pc Packages (Windows-Ms Word and Ms-Excel)</li>
              <li>Rdbms Programming in FoxPro</li>
               <li> Financial Accounting-Tally</li>
                <li>Opps Programming C++</li>
                <li>Gui Programming in Visual Basic</li>
                <li>Web Technology & Internet</li>
                <li>System Analysis and Design</li>
            </ul>

            <h3>Part - II</h3>
            <ul style={{ listStyleType: 'circle', listStylePosition: 'inside' }}>
              <li>Training Programming and Assignments</li>
              <li>Practicals's and Assignments</li>
              <li>PViva-Voice</li>        
            </ul>
          </Box>
        </Box>
        <Box sx={{marginBottom:'20px'}}><Link to={'/contact'} style={{color:'black'}}>For More Details Contact Us</Link></Box>

        </Container>





      {/* ----------------footer---------------- */}


      <Footer/>
    </>
  )
}

export default Diploma