import React from 'react'
import { Header } from '../header-footer/Header'
import { Footer } from '../header-footer/Footer'
import '../styles/Accounting.css'
import { Box, Container } from '@mui/material'
import account from '../images/accounting.png'
import { Link } from 'react-router-dom'

const Accounting = () => {
  return (
    <>
      <Header />

      {/* -----------content----------------- */}

      <Container>
        <Box className='account-container'>
          <Box className='account-part'>
            <h2 className='abt'>Accounting Skills using Tally</h2>
            <p className='abt-content'>Our complete course covers the principles of accounting and includes in-depth training in Tally, the industry-standard software. Whether you are a beginner or wanting to improve your abilities, Genext's expert-led training will provide you with the information and practical experience you need to succeed in the world of finance.</p>
            <h3 style={{marginTop:'10px'}}>Course Duration : 4 to 5 months</h3>
            <h2 className='abt'>Tally Overview</h2>
            <p className='abt-content'>Tally is a complete Enterprise Resource Planning (ERP) system with a strong focus on accounting functionality. Although it has many more essential functions that an organization requires, the focus on accounting is truly fantastic. As a result, accounting is its most well-known aspect. This training is recommended since Tally does all that a new business needs when it first starts out. Accounting, billing, sales and profit analysis, banking, inventory, and taxation (VAT, CST, service tax, excise duty, custom duty, TDS, TCS, E-CHALLAN FORM).</p>
          </Box>
          <Box className='account-part'>
            <img src={account} className='account-img' alt='account-img' />
          </Box>
        </Box>
        <Box sx={{ marginBottom: '30px', padding: '20px' }}>
          <h2 className='abt'>Course Covers</h2>
          <Box sx={{ paddingLeft: '20px', width: '100%' }}>
            <ul className='account-ulist'>
              <li>
                <h3>Manually</h3>

                <ul>
                  <li>Journal, Ledger, Trial Balance, Cash Book, Bank Rec, Satement, Final A/C Trading, Profit & Loss and Balance Sheet</li>
                </ul>

              </li>
              <li>
                <h3>Practical</h3>

                <ul>
                  <li>Creating Company, Ledger, Trial Balance, Vouchers Entry, Import/Export Data, Data Backup, Printing, Delete and Inventory Management</li>
                </ul>

              </li>
              <li>
                <h3>Taxation</h3>

                <ul>
                  <li>GST (GSTR1, GSTR2, GSTR3, GSTR4), Vat Tax, TCS Tax, TDS Tax, Income Tax and Excise Duty</li>
                </ul>

              </li>

              <li><h3>Production Planning</h3></li>
              <li><h3>Industrial & Corporate A/C</h3></li>
              <li><h3>Internet</h3></li>
            </ul>
          </Box>
        </Box>
        <Box sx={{marginBottom:'20px'}}><Link to={'/contact'} style={{color:'black'}}>For More Details Contact Us</Link></Box>

      </Container>


      {/* -----------footer-------------------- */}
      <Footer />
    </>
  )
}

export default Accounting