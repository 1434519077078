import { Box, Container } from '@mui/material'
import React from 'react'
import { Header } from '../header-footer/Header'
import { Footer } from '../header-footer/Footer'
import ContactSection from '../section/ContactSection'
import ContactForm from '../section/ContactForm'

export const Contact = () => {
  return (
    <>
    <Header/>
    {/* ------------head--------------- */}
        <Box className='about-head'>
          <Container>
          <h1>Contact Us</h1>
          </Container>
        </Box>

        {/* ----------------details-------------- */}

        <ContactSection/>


        <ContactForm/>
        
    {/* ----------------------footer-------------------- */}
      <Footer/>
    </>
  )
}
