import React from 'react'
import { Header } from '../header-footer/Header'
import { Footer } from '../header-footer/Footer'
import { Box, Container } from '@mui/material'
import digital from '../images/digital.png'
import { Link } from 'react-router-dom'

const DigitalMarketing = () => {
  return (
    <>
        <Header/>
       {/* -----------content--------------------- */}

        <Container>
        <Box className='account-container'>
          <Box className='account-part'>
            <h2 className='abt'>Digital Marketing</h2>
            <p className='abt-content'>Begin a dynamic adventure in digital marketing with Genext's complete course. From Search Engine Optimization (SEO) to Social Media Optimization (SMO), our expert-led course will prepare you to succeed in today's digital landscape. Explore the latest methods and technologies to generate online success and boost your career in the fast-paced world of digital marketing.</p>
            <h3 style={{marginTop:'10px'}}>Course Duration : 2 to 3 months</h3>
            <h2 className='abt'>What is SEO ?</h2>
            <p className='abt-content'>SEO stands for Search Engine Optimisation. It includes a variety of approaches and strategies for increasing a website's access and rating on search engine results pages (SERPs). Keyword research, on-page and off-page optimization, link building, and technical optimization are all used to increase organic traffic and improve online presence.</p>
            <h2 className='abt'>What is SMO ?</h2>
            <p className='abt-content'>SMO stands for Social Media Optimisation. It means using social media platforms to increase brand awareness, connect with people, and drive traffic to a website. This includes developing shareable content, optimizing profiles, engaging with followers, organizing social media campaigns, and analyzing social media metrics to optimize performance and reach.</p>
          </Box>
          <Box className='account-part'>
            <img src={digital} className='account-img' alt='account-img' />
          </Box>
        </Box>
        <Box sx={{marginBottom:'20px'}}><Link to={'/contact'} style={{color:'black'}}>For More Details Contact Us</Link></Box>

        </Container>



       {/* --------------footer---------------- */}
        <Footer/>
    </>
  )
}

export default DigitalMarketing