import { Box,Container} from '@mui/material'
import React from 'react'
import { Header } from '../header-footer/Header'
import { Footer } from '../header-footer/Footer'
import PlacementForm from '../section/PlacementForm'




export const Placement = () => {

  return (
    <>
    <Header/>
      <Box>
        <Box className='about-head'>
          <Container>
          <h1>Placement</h1>
          </Container>
        </Box>
        <Container sx={{ padding: '20px', marginBottom: '30px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: { xs: 'column', sm: 'row' }}}>
          <Box sx={{ width: { xs: '100%', sm: '50%' }, marginRight: '30px',marginBottom:'30px' }}>
            <h2 className='abt'>Empower Your Career Journey with Our Job Assistance Program</h2>
            <p className='abt-content'>
              Discover the pathway to success with our comprehensive job assistance program. We provide invaluable support to help you secure employment opportunities. From resume refinement to interview coaching and industry networking, our dedicated team is committed to empowering your career journey. With our assistance, you'll gain the confidence and skills needed to thrive in today's competitive job market. Unlock your potential and embark on a fulfilling career path with us.</p>
          </Box>
          <Box sx={{ width: { xs: '100%', sm: '50%' } }}>
            <PlacementForm/>
          </Box>
        </Container>
      </Box>
      <Footer/>
    </>
  )
}
