import React from 'react'
import '../styles/TeamSection.css'
import profile1 from '../images/profile1.jpg'
import profile2 from '../images/profile2.jpg'
import profile3 from '../images/profile3.png'
import profile4 from '../images/profile4.jpg'

const TeamSection = () => {
    return (
        <>
            <section id='team'>
                <div className='container'>
                    <h1 className='heading'>Our Team</h1>
                    <div className='wrapper'>
                        <div className='team-card'>
                            <div class='team-img'>
                                <img src={profile1} alt='team' />
                            </div>
                            <div class='team-caption'>
                                <h3>Devendra Singh Rathore</h3>
                                <p>Co-Founder, Creative Directore</p>
                            </div>
                        </div>
                        <div className='team-card'>
                            <div class='team-img'>
                                <img src={profile2} alt='team' />
                            </div>
                            <div class='team-caption'>
                                <h3>Nitin Kukreja</h3>
                                <p>Senior Faculty</p>
                            </div>
                        </div>
                        <div className='team-card'>
                            <div class='team-img'>
                                <img src={profile3} alt='team' />
                            </div>
                            <div class='team-caption'>
                                <h3>Deepak Dhanvai</h3>
                                <p>Senior Faculty</p>
                            </div>
                        </div>
                        <div className='team-card'>
                            <div class='team-img'>
                                <img src={profile4} alt='team' />
                            </div>
                            <div class='team-caption'>
                                <h3>Namita Shukla</h3>
                                <p>Senior Faculty</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TeamSection