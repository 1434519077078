import React from 'react'
import { Header } from '../header-footer/Header'
import { Footer } from '../header-footer/Footer'
import { Box, Container } from '@mui/material'
import spoken from '../images/spoken.png'
import { Link } from 'react-router-dom'

const SpokenEnglish = () => {
  return (
    <>
      <Header />
      {/* ----------content----------------- */}


      <Container>
        <Box className='account-container'>
          <Box className='account-part'>
            <h2 className='abt'>Spoken English</h2>
            <p className='abt-content'>Genext has also designed a very exciting and innovative English and Personality Development Course for the twenty-first century, keeping into consideration the needs and requirements of nowadays.The lessons are interactive, sociable, and fun, with clear objectives that contribute to your overall progress and move you closer to achieving your individual language goals. We offer a range of English language courses for adults, young learners, and professionals to improve their overall communication skills. The classes are interactive, and are delivered by internationally certified trainers.</p>
            <h3 style={{marginTop:'10px'}}>Course Duration : 3 to 5 months</h3>
          </Box>
          <Box className='account-part'>
            <img src={spoken} className='account-img' alt='account-img' />
          </Box>
        </Box>
        <Box className='account-container' sx={{ alignItems: 'flex-start' }}>
          <Box className='account-part'>
            <h2 className='abt'>Module I</h2>
            <h4>Bilingual Approach of Teaching English Language</h4>
            <h4>Structural Pattern:</h4>
            <ul style={{ listStyleType: 'circle', listStylePosition: 'inside',fontSize:'14px' }}>
              <li>Introduction of Language</li>
              <li>Articles</li>
              <li>Tenses</li>
              <li>Active Voice</li>
              <li>Passive Voice</li>
              <li>Conjunction</li>
              <li>Preposition</li>
              <li>Modal</li>
              <li>Structure</li>
              <li>Parts of Speech</li>
            </ul>
          </Box>
          <Box className='account-part'>
            <h2 className='abt'>Module II</h2>
            <ul style={{ listStyleType: 'circle', listStylePosition: 'inside',fontSize:'14px' }}>
              <li>Personal Introduction</li>
              <li>Group Discussion</li>
              <li>Personal/Group Interviews</li>
              <li>Comprehensive Vocabulary Session</li>
              <li>Seminar</li>
              <li>Presentation</li>
              <li>Public Speaking Session</li>
              <li>Event Management</li>
              <li>Story Telling</li>
              <li>Rapid Question Answer Fire</li>
              <li>Extempore: Role Play</li>
              <li>Ad-Making</li>
              <li>Essay Writing</li>
              <li>Letters/Applications</li>
              <li>Mock Call Preparation</li>
            </ul>
          </Box>
        </Box>
        <Box sx={{marginBottom:'20px'}}><Link to={'/contact'} style={{color:'black'}}>For More Details Contact Us</Link></Box>
      </Container>



      {/* -----------footer-------------------- */}
      <Footer />
    </>
  )
}

export default SpokenEnglish