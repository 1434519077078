import webDesign from '../images/webdesign.png'
// import webDevelop from '../images/webdevelopment.png'
import account from '../images/accounting.png'
import graphics from '../images/graphics.png'

const data = [
    // {
    //     img: webDesign,
    //     name: 'Web Design',
    //     des: 'Learn the basic concepts of web design with our course, which includes training on Adobe, Figma, Photoshop and also Learn UI/UX.',
    //     src: '/web-design'
    // },
    {
        img: webDesign,
        name: 'Design/Development',
        des : 'In this includes HTML, CSS, JavaScript, and other technologies to build dynamic, interactive and responsive websites.',
        src:'/design-development'
    },
    {
        img: account,
        name: 'Accounting',
        des : 'Learn the principles of accounting in this course with a focus on Tally software. Learn how to handle financial transactions well.',
        src:'/accounting'
    },
    {
        img: graphics,
        name: 'Graphics Designing',
        des : 'In this course learn principles of design with Adobe Photoshop and Figma so you can create colorful print and digital media graphics.',
        src:'/graphics'
    }
];

export default data;