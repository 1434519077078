import webDesign from '../images/webdesign.png'
import account from '../images/accounting.png'
import graphics from '../images/graphics.png'
import programming from '../images/programming.png'
import Computer from '../images/computer.png'
import digital from '../images/digital.png'
import spoken from '../images/spoken.png'
import diploma from '../images/practical-learning.png'

const data2 = [
    {
        img: webDesign,
        name: 'Design & Development',
        des: 'Learn the basic concepts of web design with our course, which includes training on Adobe, Figma, Photoshop and also Learn UI/UX.',
        src: '/design-development'
    },
    {
        img: account,
        name: 'Accounting',
        des : 'Learn the principles of accounting in this course with a focus on Tally software. Learn how to handle financial transactions well.',
        src:'/accounting'
    },
    {
        img: graphics,
        name: 'Graphics Designing',
        des : 'In this course learn principles of design with Adobe Photoshop and Figma so you can create colorful print and digital media graphics.',
        src:'/graphics'
    },
    {
        img: programming,
        name: 'Programming',
        des : ' You can embark on a journey to master various programming languages and Data Structures & Algorithms (DSA)',
        src:'/programming'
    },
    {
        img: Computer,
        name: 'Basics of Computer',
        des : 'Master essential computer skills with our complete Basic Computer Course, covering fundamental usage principles.',
        src:'/basic-computer'
    },
    {
        img: digital,
        name: 'Digital Marketing',
        des : 'Explore the dynamic world of digital marketing and improve your online presence with this complete training.',
        src:'/digital-marketing'
    },
    {
        img: spoken,
        name: 'Spoken English',
        des : 'Enhance your English communication skills and boost confidence with our practical Spoken English course',
        src:'/spoken-english'
    },
    {
        img: diploma,
        name: 'Diploma',
        des : 'Advance your career with in-depth knowledge and abilities from our Diploma in DCA and PGDCA courses.',
        src:'/diploma'
    }
];

export default data2;