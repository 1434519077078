import { Route, Routes } from "react-router-dom";
import {Home} from './components/pages/Home'
import {About} from './components/pages/About';
import {Courses} from './components/pages/Courses';
import {Franchisee} from './components/pages/Franchisee';
import {Placement} from './components/pages/Placement';
import {Contact} from './components/pages/Contact';
import {Pagenotfound} from './components/pages/Pagenotfound';
import Programming from "./components/courses/Programming";
import WebDesign from "./components/courses/WebDesign";
import Accounting from "./components/courses/Accounting";
import Diploma from "./components/courses/Diploma";
import DigitalMarketing from "./components/courses/DigitalMarketing";
import BasicComputer from "./components/courses/BasicComputer";
import SpokenEnglish from "./components/courses/SpokenEnglish";
import CProgramming from "./components/languages/CProgramming";
import CplusProgramming from "./components/languages/CplusProgramming";
import Python from "./components/languages/Python";
import Java from "./components/languages/Java";
import DSA from "./components/languages/DSA";
import Graphics from "./components/courses/Graphics";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <div>
      <ScrollToTop/>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path="/about" element={<About/>} />
          <Route path="/courses" element={<Courses/>} />
          <Route path="/franchisee" element={<Franchisee/>} />
          <Route path="/placement" element={<Placement/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/programming" element={<Programming/>} />
          <Route path="/design-development" element={<WebDesign/>} />
          <Route path="/graphics" element={<Graphics/>}/>
          <Route path="/accounting" element={<Accounting/>} />
          <Route path="/diploma" element={<Diploma/>} />
          <Route path="/digital-marketing" element={<DigitalMarketing/>} />
          <Route path="/basic-computer" element={<BasicComputer/>} />
          <Route path="/spoken-english" element={<SpokenEnglish/>} />
          <Route path="/Cprogramming" element={<CProgramming/>}/>
          <Route path="/Cplus" element={<CplusProgramming/>} />
          <Route path="/python" element={<Python/>}/>
          <Route path="/java" element={<Java/>}/>
          <Route path="/dsa" element={<DSA/>}/>
          <Route path="*" element={<Pagenotfound/>} />
        </Routes>
      
      
    </div>
  );
}

export default App;
