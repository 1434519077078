import React from 'react'
import '../styles/Section2.css'
import syllabus from '../images/syllabus.png'
import teacher from '../images/teacher.png'
import pratical from '../images/practical-learning.png'
// import { Container } from '@mui/material'

const Section2 = () => {
    return (
        <>
            <section id='chooseus'>
                <div className='chooseus-container'>
                    <div className='chooseus-details'>
                        <h1 className='chooseus-head'>Why to Choose Us ?</h1>
                        <ul className='chooseus-list'>
                            <li className='chooseus-list-point'><img src={syllabus} alt='syllabus' className='chooseus-img' />
                                <div className='chooseus-list-point-inside'><h3>In depth Courses</h3>
                                    <p>Access a wide range of courses designed by experts to satisfy industry standards to boost career prospects.</p>
                                </div>
                            </li>
                            <li className='chooseus-list-point'>
                                <img src={teacher} alt='teacher' className='chooseus-img' />
                                <div className='chooseus-list-point-inside'><h3>Expert Teachers</h3>
                                    <p>Learn from qualified teachers and industry experts who will provide you with suitable advice and support during the course of your training.</p>
                                </div>
                            </li>
                            <li className='chooseus-list-point'>
                                <img src={pratical} alt='practical' className='chooseus-img' />
                                <div className='chooseus-list-point-inside'><h3>Practical Learning</h3>
                                    <p>Enroll yourself in interactive, practical courses that will provide you with real-world skills and the confidence to succeed in the field of your choice.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Section2