import React from 'react'
import { Footer } from '../header-footer/Footer'
import { Header } from '../header-footer/Header'
import { Box, Container } from '@mui/material'
import C from '../images/C.png'
import { Link } from 'react-router-dom'

const CProgramming = () => {
  return (
    <>
      <Header />
      {/* -----------content----------------- */}

      <Container>
        <Box className='account-container'>
          <Box className='account-part'>
            <h2 className='abt'>C Programming</h2>
            <p className='abt-content'>Explore the fundamentals of programming with our C Programming course. Learn one of the most influential programming languages, from fundamental syntax to advanced principles.</p>
            <h3 style={{marginTop:'10px'}}>Course Duration : 2 months</h3>
            <h3 className='abt'>Course Content</h3>
            <ul style={{ listStyleType: 'circle', listStylePosition: 'inside', fontSize: '14px' }}>
              <li>Introduction to C programming language and its significance</li>
              <li>  Basic syntax, data types, and control structures</li>
              <li> Functions and modular programming</li>
              <li>Pointers and memory management</li>
              <li> File handling and input/output operations</li>
              <li> Arrays, strings, and structures</li>
              <li> Dynamic memory allocation</li>
              <li> Debugging and error handling techniques</li>
              <li>Practical exercises and projects to reinforce learning</li>
            </ul>
          </Box>
          <Box className='account-part'>
            <img src={C} className='account-img' alt='account-img' />
          </Box>
        </Box>
        <Box sx={{marginBottom:'20px'}}><Link to={'/contact'} style={{color:'black'}}>For More Details Contact Us</Link></Box>

      </Container>

      {/* ----------footer-------------------- */}

      <Footer />
    </>
  )
}

export default CProgramming