import React from 'react'
import '../styles/Section.css'
import { Link } from 'react-router-dom'

const Card = (props) => {
  return (
    <>
    <div className='card'>
            <img src={props.image} alt='cardimage'/>
            <h3>{props.name}</h3>
            <p>{props.des}</p>
            <Link to={props.src} className='btn'>read more</Link>
    </div>
    </>
  )
}

export default Card