import React from 'react'
import { Footer } from '../header-footer/Footer'
import { Header } from '../header-footer/Header'
import { Box, Container } from '@mui/material'
import Cplus from '../images/C++.png'
import { Link } from 'react-router-dom'

const CplusProgramming = () => {
  return (
    <>
      <Header />
      {/* -----------content----------------- */}

      <Container>
        <Box className='account-container'>
          <Box className='account-part'>
            <h2 className='abt'>C++ Programming</h2>
            <p className='abt-content'>Our complete course will teach you everything you need to know about C++ programming. Learn the language from basics to advanced ideas, such as object-oriented programming and data structures.</p>
            <h3 style={{marginTop:'10px'}}>Course Duration : 2 to 3 months</h3>
            <h3 className='abt'>Course Content</h3>
            <ul style={{ listStyleType: 'circle', listStylePosition: 'inside', fontSize: '14px' }}>
              <li>Introduction to C++ and its significance in programming</li>
              <li>  Basic syntax, data types, and control structures</li>
              <li> Object-oriented programming concepts: classes, objects, inheritance, polymorphism</li>
              <li>Pointers, memory management, and dynamic memory allocation</li>
              <li> File handling and input/output operations</li>
              <li>Standard Template Library (STL) for data structures and algorithms</li>
              <li>Exception handling and error management</li>
              <li>Practical exercises and projects to reinforce learning</li>
            </ul>
          </Box>
          <Box className='account-part'>
            <img src={Cplus} className='account-img' alt='account-img' />
          </Box>
        </Box>
        <Box sx={{marginBottom:'20px'}}><Link to={'/contact'} style={{color:'black'}}>For More Details Contact Us</Link></Box>

      </Container>

      {/* ----------footer-------------------- */}
      <Footer />
    </>
  )
}

export default CplusProgramming