import React from 'react'
import '../styles/ContactSection.css'
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { Container } from '@mui/material';

const ContactSection = () => {
  return (
    <>
      <Container sx={{ marginTop: '0' }}>
        <section id='contact'>
          {/* <h1 className='heading'>Contact US</h1> */}
          <div className='contact-container'>
            <div className='contact-content'>
              <div className="left">
                <div className="phone details">
                  <FaPhone className='contact-icon' />
                  <div className="topic">Phone</div>
                  <div className="text">H.O. +91-9893078853 </div>
                  <div className="text">B.O. +91-98273-03634</div>
                </div>
                <div className="email details">
                  <MdEmail className='contact-icon' />
                  <div className="topic">Email</div>
                  <div className="text">info@genext.org.in</div>
                  {/* <div className="text">vishujainmhs@gmail.com</div> */}
                </div>
                <div className="address details">
                  <FaLocationDot className='contact-icon' />
                  <div className="topic">Address</div>
                  <div className="text">Kandhari Plaza, </div>
                  <div className="text">Opp. Annapurna Temple, Indore</div>
                </div>
              </div>
              {/* -------------------------------------- */}
              <div class="right">

                <iframe width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Kandhari%20Plaza,%20Opp.%20Annapurna%20Temple,%20Indore%20indore+()&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> <script type='text/javascript' src='https://embedmaps.com/google-maps-authorization/script.js?id=d4085fb910fbcd1fbda645379ff7025e94d45f37'></script>
              </div>
            </div>
            <div className='contact-content'>
              <div className="address details BO">
                <FaLocationDot className='contact-icon' />
                <div className="topic">Branch Office</div>
                <div className="text">7 Ahinsa Tower, 2nd Floor,</div>
                <div className="text">M.G.Road. , Oop.Kalyan Jwellers, Indore</div>
              </div>
              <div className="address details BO">
                <FaLocationDot className='contact-icon' />
                <div className="topic">Branch Office</div>
                <div className="text">117,Pragati Nagar,</div>
                <div className="text">Rajendra Nagar(Tom & Jerry Play School), Indore</div>
              </div>
              <div className="address details BO">
                <FaLocationDot className='contact-icon' />
                <div className="topic">Branch Office</div>
                <div className="text">83-A, Sudama Nagar, Narendra</div>
                <div className="text">Tiwari Marg,Oop, Chhatrapati School, Indore</div>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </>
  )
}

export default ContactSection