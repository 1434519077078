import { Box, Container } from '@mui/material'
import React from 'react'
import '../styles/Aboutstyle.css'
import { Header } from '../header-footer/Header'
import { Footer } from '../header-footer/Footer'

export const Franchisee = () => {
  return (
    <>
    <Header/>


    {/* -------content--------------------- */}


      <Box>
        <Box className='about-head'>
          <Container>
          <h1>Franchise</h1>
          </Container>
        </Box>
        <Container sx={{ padding: '20px', marginBottom: '30px' }}>
          <Box>
            <h2 className='abt'>Franchise Opportunity</h2>
            <p className='abt-content'>Welcome to Genext Franchise Opportunities! Join our network of successful educational ventures and become a part of the Genext family. As a franchisee, you'll have the opportunity to bring quality education and professional development to your community. With our proven business model and comprehensive support system, you can embark on a rewarding journey of entrepreneurship in the education sector. Explore the benefits of partnering with Genext and take the first step towards a lucrative franchise opportunity today!</p>
          </Box>
          <Box>
            <h2 className='abt'>Requirement for Franchise Opening</h2>
            <ul style={{listStyle:'disc',listStylePosition:'inside'}}>
              <li className='abt-content'><span style={{fontWeight:'bold'}}>Premises required:</span> 1000 to 1500 square feet of space suitable for operating a center.</li>
              <li className='abt-content'><span style={{fontWeight:'bold'}}>Investment needed:</span> Approximately 3 to 5 lakhs for infrastructure, software, hardware, and staffing.</li>
              <li className='abt-content'><span style={{fontWeight:'bold'}}>Commitment to Quality:</span> Franchisees should be committed to maintaining the highest standards of education and service delivery.</li>
            </ul>
          </Box>
        </Container>
      </Box>

      {/* -----------footer----------------------- */}
      <Footer/>
    </>
  )
}
