import React from 'react'
import { Footer } from '../header-footer/Footer'
import { Header } from '../header-footer/Header'
import { Box, Container } from '@mui/material'
import graphics from '../images/graphics.png'
import { Link } from 'react-router-dom'

const Graphics = () => {
  return (
    <>
        <Header/>
        {/* -----------content----------------- */}

      <Container>
        <Box className='account-container'>
          <Box className='account-part'>
            <h2 className='abt'>Graphics Designing</h2>
            <p className='abt-content'>Graphic designing is the art and practice of planning and projecting ideas and experiences with visual and textual content.</p>
            <h3 style={{marginTop:'10px'}}>Course Duration : 2 to 3 months</h3>
            <Box >
            <h3 className='abt'>Course Content</h3>
            <ul style={{ listStyleType: 'circle', listStylePosition: 'inside',fontSize:'14px' }}>
              <li>Introduction to computers graphics</li>
              <li>OS’s: MS-WINDOWS; MS-DOS</li>
              <li>Installation of required software</li>
              <li>Graphics designing using Coral Draw </li>
              <li>Layout designing using adobe Page Maker </li>
              <li>Photo editing using adobe Photoshop</li>
              <li>Working with printers & scanners & Hindi typing</li>
            </ul>
          </Box>
          </Box>
          <Box className='account-part'>
            <img src={graphics} className='account-img' alt='account-img' />
          </Box>
        </Box>
         
        
        <Box sx={{marginBottom:'20px'}}><Link to={'/contact'} style={{color:'black'}}>For More Details Contact Us</Link></Box>

      </Container>


      {/* -----------footer-------------------- */}
        <Footer/>
    </>
  )
}

export default Graphics