import { Box } from '@mui/material'
import '../styles/Homestyle.css'
import React from 'react'
import { Header } from '../header-footer/Header'
import { Footer } from '../header-footer/Footer'
import Section from '../section/Section'
import Section2 from '../section/Section2'
import TeamSection from '../section/TeamSection'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import data from '../data/Data'

export const Home = () => {

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 2000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 500 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1
    }
  };

  return (
    <>
      <Header />

      {/* ----------------slider----------------------------- */}

      <Box id='slideshow'>

        <Carousel responsive={responsive} autoPlay={true} autoPlaySpeed={3000} arrows={false} showDots rewind={true} rewindWithAnimation={true} shouldResetAutoplay={true} >
          {
            data.map((item, index) => {
              return (
                <div key={index} style={{ width: '100%', height: '95vh', position: 'relative' }} >
                  <img src={item.url} style={{ width: '100%', height: '100%', objectFit: 'cover'}} alt='homeimg' />
                  <div className='description'>
                    <h1>{item.title}</h1>
                    <p>{item.body}</p>
                  </div>
                </div>
              )
            })
          }
        </Carousel>
      </Box>

      {/* ------------content --------------------------------------------- */}

      <Section />

      <Section2 />

      <TeamSection />


      <Footer />

    </>
  )
}
