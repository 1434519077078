import React from 'react'
import { Header } from '../header-footer/Header'
import { Footer } from '../header-footer/Footer'
import { Box, Container } from '@mui/material'
import { Link } from 'react-router-dom'


const WebDesign = () => {
  
  return (
    <>
      <Header />
      {/* ----------content----------------- */}


      <Container>
        <Box className='account-container'>
          <Box>
            <h2 className='abt'>Design & Development</h2>
            <p className='abt-content'>With our complete course, you'll be trained in the world of web design and development. Explore the complexities of designing visually attractive interfaces and developing solid, effective websites and applications.</p>
            <h3 style={{ marginTop: '10px' }}>Course Duration : 8 to 9 months </h3>
            <h3 className='abt'>Course Content : </h3>
            <Box>
            <h3 style={{margin:'10px 0'}}>Web Designing</h3>
            <p className='abt-content'>Website design refers to the planning, construction, and maintenance of websites. Website design includes information architecture, website structure, user interface, navigation design, website layout, colors, contrasts, fonts, imagery (photography), and icon design.</p>
            <h4 style={{margin:'10px 0'}}>Includes :</h4>
            <ul style={{ listStyleType: 'circle', listStylePosition: 'inside',fontSize:'14px' }}>
              <li>Introduction to Design Principles: Color theory, typography, layout, and visual hierarchy</li>
              <li>User Experience (UX) Design: Understanding user behavior, wireframing, and prototyping</li>
              <li>Graphic Design Tools: Adobe Photoshop, Illustrator, and Sketch for creating designs</li>
              <li>Responsive Web Design: Designing websites that adapt to various devices and screen sizes</li>
              <li>CSS Frameworks: Utilizing frameworks like Bootstrap for rapid development</li>
              <li> Frontend Development: HTML5 and CSS3 for structuring and styling web pages</li>
              <li> UI Animation: Adding interactivity and animations using CSS and JavaScript libraries</li>
              <li>Designing for Accessibility: Ensuring inclusivity and usability for all users</li>
            </ul>
            </Box>
            <Box>
            <h3 style={{margin:'10px 0'}}>Web Development</h3>
            <p className='abt-content'>Web development broadly refers to the tasks associated with developing websites for hosting via intranet or Internet. The Web development process includes Web design, Web content development, client-side/server-side scripting and network security configuration, among other tasks.</p>
            <h4 style={{margin:'10px 0'}}>Includes :</h4>
            <ul style={{ listStyleType: 'circle', listStylePosition: 'inside',fontSize:'14px' }}>
              <li>Introduction to Web Technologies: HTML, CSS, and JavaScript fundamentals</li>
              <li>Backend Development: Server-side scripting languages such as PHP, Node.js, or Python</li>
              <li>Database Management: MySQL, MongoDB, or Firebase for storing and retrieving data</li>
              <li>Server Configuration: Setting up and configuring web servers (Apache, Nginx)</li>
              <li>RESTful APIs: Building and consuming APIs for communication between frontend and backend</li>
              <li> Version Control: Git and GitHub for collaborative development and code management</li>
              <li> Deployment: Hosting websites on servers and deploying applications to production</li>
              <li>Security Best Practices: Protecting websites from common vulnerabilities and attacks</li>
            </ul>
            </Box>
          </Box>
        </Box>
        {/* <Box className='account-container' sx={{ alignItems: 'flex-start' }}>
            <h2></h2>
        </Box> */}
        <Box sx={{ marginBottom: '20px' }}><Link to={'/contact'} style={{ color: 'black' }}>For More Details Contact Us</Link></Box>
      </Container>




      {/* -----------footer-------------------- */}
      <Footer />
    </>
  )
}

export default WebDesign