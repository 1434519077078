import React from 'react'
import Card from './Card'
import data from '../data/Data2'
import '../styles/Section.css'
import { Container } from '@mui/material'

const Section = () => {
    const card = data.map(item => {
        return <Card image={item.img} name={item.name} des={item.des} src={item.src}/>
    })
    return (
        <>
        <Container>
            <section id='popularcourse'>
                <div className='container'>
                    <h1 className='heading'>Popular Courses</h1>
                    <div className='wrapper' style={{gap:'3rem'}}>
                        {card}
                    </div>
                </div>
            </section>
            </Container>
        </>
    )
}

export default Section