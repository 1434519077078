import React, { useState } from 'react'
import '../styles/Headerstyles.css'
import logo from '../images/logo.png'
import { Container, Typography, Box, Button } from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';


export const Header = () => {
  const [show, setShow] = useState(false);
  const [disp, setDisp] = useState(false);
  const [show2, setShow2] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Box className='head'  >
        <Container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ paddingRight: '8px', fontSize: '12px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <LocalPhoneIcon sx={{ fontSize: '22px', paddingRight: '8px' }} />call us:- +91-98930-78853
            </Typography>
            <Typography sx={{ paddingRight: '8px', fontSize: '12px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <EmailIcon sx={{ fontSize: '22px', paddingRight: '8px' }} />mail us :- info@genext.org.in
            </Typography>
          </Box>
          <Box sx={{ width: '130px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Link to={'https://www.facebook.com/genextpalasia/'}><FacebookIcon /></Link>
            <Link to={'https://www.youtube.com/channel/UC8WD0V2XVqa7xfbpQgVTirg?view_as=subscriber'}><YouTubeIcon /></Link>
            <Link to={'https://twitter.com/genextweb16'}><TwitterIcon /></Link>
            <Link to={'https://www.linkedin.com/in/genextweb/'}><LinkedInIcon /></Link>
          </Box>
        </Container>
      </Box>
      <Box className='navigation'  >
        <Container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
          <Box onClick={()=>{
            navigate("/");
          }}>
            <img src={logo} alt='Genext-logo' className='logo' />
          </Box>
          <Box>
            <ul className={show ? "nav-menu" : "nav-menu chk"}>
              <li>
                <NavLink to={'/'}>Home</NavLink>
              </li>
              <li>
                <NavLink to={'/about'}>About</NavLink>
              </li>
              <li style={{ display: 'flex', alignItems: 'center' }}>
                
                <li ><NavLink to={'/courses'} >Courses </NavLink></li>
               
                
                <span onClick={() => {
                  disp ? setDisp(false) : setDisp(true)
                  show2 ? setShow2(false) : setShow2(true)
                }} style={{ color: 'white',marginTop:'5px'}}>{
                    show2 ?  <ArrowDropUpIcon />: <ArrowDropDownIcon />
                  }
                </span>
                <ul className={disp ? "nav-inner" : "nav-inner dp"}>
                  <li><NavLink to={'/programming'}>Programming</NavLink></li>
                  <li><NavLink to={'/design-development'}>Design & Development</NavLink></li>
                  <li><NavLink to={'/graphics'}>Graphics Designing</NavLink></li>
                  <li><NavLink to={'/accounting'}>Accounting</NavLink></li>
                  <li><NavLink to={'/diploma'}>Diploma</NavLink></li>
                  <li><NavLink to={'/digital-marketing'}>Digital Marketing</NavLink></li>
                  <li><NavLink to={'/basic-computer'}>Basic Computer</NavLink></li>
                  <li><NavLink to={'/spoken-english'}>Spoken English</NavLink></li>
                </ul>
             
              </li>
              <li>
                <NavLink to={'/franchisee'}>Franchise</NavLink>
              </li>
              <li>
                <NavLink to={'/placement'}>Placement</NavLink>
              </li>
              <li>
                <NavLink to={'/contact'}>Contact</NavLink>
              </li>
            </ul>
          </Box>
          <Box className='cc'>
            <Button variant='text' sx={{ color: 'white' }} onClick={() => {
              show ? setShow(false) : setShow(true)
            }}>
              {
                show ? <CloseIcon /> : <MenuIcon />
              }
            </Button>

          </Box>

        </Container>

      </Box>


    </>
  )
}

