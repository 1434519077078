import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import '../styles/Footerstyle.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export const Footer = () => {
  return (
    <>
      <Box>
        <Box sx={{ background: 'black', color: '#bbbbbb', padding: '30px 0', width: '100%'}}>
          <Container>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <Box className='footer-col'>
                <h4>Head Office</h4>
                <ul>
                  <li><Link>Kandhari Plaza, Opp. Annapurna Temple, Indore</Link></li>
                  <li> Ph: 0731-4078854</li>
                  <li>M: 9893078853, 9202220930</li>
                </ul>
              </Box>
              <Box className='footer-col'>
                <h4>Branch Office</h4>
                <ul >
                  <li><Link to={''}>1) 7 Ahinsa Tower, 2nd Floor , M.G.Road. , Oop.Kalyan Jwellers, Indore</Link></li>
                  <li><Link to={''}>2) 117,Pragati Nagar, Rajendra Nagar (Tom & Jerry Play School), Indore</Link></li>
                  <li><Link to={''}>3) 83-A, Sudama Nagar, Narendra Tiwari Marg,Oop, Chhatrapati School, Indore</Link></li>
                </ul>
              </Box>
              <Box className='footer-col'>
                <h4>Quick Links</h4>
                <ul>
                  <li><NavLink to={'/'}>Home</NavLink></li>
                  <li><NavLink to={'/about'}>About</NavLink></li>
                  <li><NavLink to={'/courses'}>Courses</NavLink></li>
                  <li><NavLink to={'/franchisee'}>Franchise</NavLink></li>
                  <li><NavLink to={'/placement'}>Placement</NavLink></li>
                  <li><NavLink to={'/contact'}>Contact</NavLink></li>
                </ul>
              </Box>
              <Box className='footer-col'>
                <h4>Contact Us</h4>
                <div className='social-links'>
                  <Link to={'https://www.facebook.com/genextpalasia/'}><FacebookIcon className='icon'/></Link>
                  <Link to={'https://www.youtube.com/channel/UC8WD0V2XVqa7xfbpQgVTirg?view_as=subscriber'}><YouTubeIcon className='icon'/></Link>
                  <Link to={'https://twitter.com/genextweb16'}><TwitterIcon className='icon'/></Link>
                  <Link to={'https://www.linkedin.com/in/genextweb/'}><LinkedInIcon className='icon'/></Link>
                </div>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box sx={{ textAlign: 'center', background: '#0c0d0c', color: 'grey',padding:'8px'}}>
          <Container sx={{display:'flex',flexDirection:{xs:'column',sm:'row'},justifyContent:'space-between',alignItems:'center'}}>
          <Typography sx={{fontSize:'14px'}}>copyright &#169; 2024 Genext. All rights reserved. </Typography>
          <Typography sx={{fontSize:'14px'}}>Powered by and Maintain by Crystal It Soft.</Typography>
          </Container>
        </Box>
      </Box>


    </>
  )
}
