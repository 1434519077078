import React from 'react'
import { Footer } from '../header-footer/Footer'
import { Header } from '../header-footer/Header'
import { Box, Container } from '@mui/material'
import java from '../images/java.png'
import { Link } from 'react-router-dom'

const Java = () => {
  return (
    <>
        <Header/>
          {/* -----------content----------------- */}

      <Container>
        <Box className='account-container'>
          <Box className='account-part'>
            <h2 className='abt'>Java Programming</h2>
            <p className='abt-content'>Our complete course will help you explore all the possibilities of Java programming. From fundamentals to complex applications, learn one of the most versatile software development languages.</p>
            <h3 style={{marginTop:'10px'}}>Course Duration : 6 to 7 months</h3>
            <h3 className='abt'>Course Content</h3>
            <ul style={{ listStyleType: 'circle', listStylePosition: 'inside', fontSize: '14px' }}>
              <li>Introduction to Programming Language</li>
              <li>  Decision, Selection and Looping</li>
              <li> Classes, Objects& Method</li>
              <li>Array, Strings and Vectors</li>
              <li> Interface, Inheritance and Packages</li>
              <li>Multi threaded Programming</li>
              <li>Exceptional and Error Handling</li>
              <li>Applet Programming</li>
              <li>Web Component/Server Component</li>
              <li>Networking Programming</li>
              <li>Java Data Base Connectivity</li>
              <li>Introduction to Programming Language</li>
              <li>Rim (Remode Method Invocation)</li>
              <li>Jsp-Jdbc Connectivity</li>
              <li>Servlet / Java Beans Programming’s Struts Hybernet</li>
              <li>Practical exercises and projects to reinforce learning</li>
            </ul>
          </Box>
          <Box className='account-part'>
            <img src={java} className='account-img' alt='account-img' />
          </Box>
        </Box>
        <Box sx={{marginBottom:'20px'}}><Link to={'/contact'} style={{color:'black'}}>For More Details Contact Us</Link></Box>

      </Container>

      {/* ----------footer-------------------- */}
       <Footer/> 
    </>
  )
}

export default Java