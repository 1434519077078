import home1 from '../images/home.jpg'
import home2 from '../images/home2.jpg'
import home3 from '../images/home3.jpg'
const data=[
    {
        url: home1,
        title: 'We Provide you Best Quality Education',
        body: 'Experience excellence in education with our unparalleled commitment to quality. We provide the best-in-class education that empowers you for success.'
    },
    {
        url: home2,
        title: 'Elevate Your Skills with US.',
        body: 'Unlock your potential and elevate your skills with us. Experience transformative learning opportunities designed to propel your personal and professional growth.'
    },
    {
        url: home3,
        title: 'Start your Journey here.',
        body: 'Begin your journey towards success with us. Explore endless possibilities and opportunities to achieve your goals.'
    },
]
export default data;